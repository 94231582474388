import LeftArticle from "../../layouts/LeftArticle";
import RightArticle from "../../layouts/RightArticle";
import Equipments from "../../components/Equipments";
import { RegularObject } from "../../util/Types";
import { useOutletContext } from "react-router-dom";

export default function Shelves(props: RegularObject) {
  const SITE_URL = useOutletContext();
  return (
    <Equipments
      intro={{
        heading: "Shelves",
        subHeading: `We are into producing shelves and cabinets ranging from light duty shelves to middle duty shelves and cabinets. Shelves from our company can be used in homes, businesses, stores, or elsewhere to hold items that are being displayed, stored, or offered for sale.`,
      }}
      imageUrL="bg-[url(https://bahengineeringconsultant.com/images/home_power.jpg)]"
      elements={
        <>
          <LeftArticle
            title="Light Duty Shelves"
            description={[
              `It is the ideal shelf system to store products that are not too heavy and do not take up a lot of space with light and medium-duty shelving systems. It can adapt to any kind of storage space thanks to its easily adjustable eye gaps. A strong and rigid material is used in this system.`,
              `Light-duty shelving systems provide easy access to your products and give your storage space a stylish look. It can be converted into mezzanine (deck) platforms in high-altitude storage environments. Quality being the utmost priority, we manufacture and supply an extensive range of Light Duty shelves.`,
            ]}
            imageSrc={`${SITE_URL}light_duty_shelf.jpg`}
            imageAlt="Light Duty Shelves"
          />
          <RightArticle
            title="Middle Duty Shelves"
            description={[
              `Middle Duty Shelves are absolute storage systems perfect for warehouse and industrial applications. These characteristically include automotive and other spare hardware, parts, bulk retail, and common purposes carton and archive storage. The Middle Duty Storage Shelves can be configured to accommodate a wide range of storage requirements. These shelves are suitable for the storage of small boxes and cartons. Further, these shelves are available with an adjustable shelving system along with a load-bearing capacity of up to 1.2 tons per level.`,
              `The features are lightweight, good durability, superb accuracy, fine finishing, very long-lasting, superior strength, and easy to install and assemble.`,
            ]}
            imageSrc={`${SITE_URL}middle_duty_shelf.jpg`}
            imageAlt="Middle Duty Shelves"
          />
          <LeftArticle
            title="Cabinets"
            description={[
              `A cabinet is a box-shaped piece of furniture with doors and/or drawers for storing miscellaneous items. Some cabinets standalone while others are built into a wall or are attached to it like a medicine cabinet. Cabinets are typically made of wood (solid or with veneers or artificial surfaces), coated steel (common for medicine cabinets), or synthetic materials.`,
              `Commercial-grade cabinets differ in the materials used, which are called casework, case goods, or case furniture. We make wonderful cabinets for domestic and industrial purposes.`,
            ]}
            imageSrc={`${SITE_URL}cabinet.jpg`}
            imageAlt="Cabinets"
          />
        </>
      }
    />
  );
}
